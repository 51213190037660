var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("back-button", { attrs: { to: "/admin/" } }, [
        _vm._v("Back to Settings"),
      ]),
      _c("div", { staticClass: "avo-header-text" }, [
        _vm._v("Data Management"),
      ]),
      _c(
        "b-row",
        { staticClass: "m-3" },
        [
          _c(
            "b-col",
            { attrs: { cols: "4" } },
            [
              _c("b-form-input", {
                attrs: {
                  id: "filter-input",
                  type: "search",
                  placeholder: "Type to Search",
                },
                model: {
                  value: _vm.filterSearchText,
                  callback: function ($$v) {
                    _vm.filterSearchText = $$v
                  },
                  expression: "filterSearchText",
                },
              }),
            ],
            1
          ),
          _c("b-col"),
          _c(
            "b-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "m-2 avo-primary-btn",
                  attrs: { variant: "primary" },
                  on: { click: _vm.saveMapping },
                },
                [_vm._v("Save mapping")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("unmatched-payers-table", {
        attrs: {
          filter: _vm.filterSearchText,
          carrier_key: "Carrier_ID",
          carrier_key_label: "Carrier ID",
          pm_system: "Allscripts",
          loading: _vm.loading,
        },
        model: {
          value: _vm.allscripts_payers,
          callback: function ($$v) {
            _vm.allscripts_payers = $$v
          },
          expression: "allscripts_payers",
        },
      }),
      _c("unmatched-payers-table", {
        attrs: {
          filter: _vm.filterSearchText,
          carrier_key: "insId",
          carrier_key_label: "Insurance_ID",
          pm_system: "eClinicalWorks EBO",
          loading: _vm.loading,
        },
        model: {
          value: _vm.ecw_payers,
          callback: function ($$v) {
            _vm.ecw_payers = $$v
          },
          expression: "ecw_payers",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }