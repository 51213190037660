import { render, staticRenderFns } from "./Data.vue?vue&type=template&id=1d46b0c3&"
import script from "./Data.vue?vue&type=script&lang=js&"
export * from "./Data.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ethan/Documents/Github/avosina/web/avosina-claims/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1d46b0c3')) {
      api.createRecord('1d46b0c3', component.options)
    } else {
      api.reload('1d46b0c3', component.options)
    }
    module.hot.accept("./Data.vue?vue&type=template&id=1d46b0c3&", function () {
      api.rerender('1d46b0c3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/dataManagement/Data.vue"
export default component.exports