var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "table-header-text" },
        [
          _c(
            "b-row",
            [
              _c("b-col", [
                _vm._v(" Unmatched " + _vm._s(_vm.pm_system) + " Payers "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("b-table", {
        attrs: {
          "per-page": 20,
          "current-page": _vm.currentPage,
          items: _vm.value,
          fields: _vm.fields,
          filter: _vm.filter,
          "sort-icon-left": "",
          "show-empty": "",
          "empty-text": "No unmatched payers for " + _vm.pm_system,
          "empty-filtered-text": "Nothing here matches your search",
          busy: _vm.loading,
        },
        on: {
          filtered: function ($event) {
            return _vm.onFiltered($event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "cell(flag_bad_address)",
            fn: function (data) {
              return [
                _c(
                  "b-form-group",
                  [
                    _c(
                      "b-form-checkbox",
                      {
                        model: {
                          value: data.item.flag_bad_address,
                          callback: function ($$v) {
                            _vm.$set(data.item, "flag_bad_address", $$v)
                          },
                          expression: "data.item.flag_bad_address",
                        },
                      },
                      [_vm._v(" Bad address ")]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "cell(last_failed_claim_date)",
            fn: function (data) {
              return [
                data.item.last_failed_claim_date
                  ? _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.formatDateShort(
                              data.item.last_failed_claim_date
                            )
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cell(kebab)",
            fn: function (data) {
              return [
                _c("payer-multiselect", {
                  key: data.item.Carrier_ID,
                  attrs: { multiple: false },
                  model: {
                    value: data.item.target_ref,
                    callback: function ($$v) {
                      _vm.$set(data.item, "target_ref", $$v)
                    },
                    expression: "data.item.target_ref",
                  },
                }),
              ]
            },
          },
          {
            key: "table-busy",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "text-center text-info my-2" },
                  [
                    _c("b-spinner", { staticClass: "align-middle" }),
                    _c("strong", [_vm._v("Loading...")]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm.total_rows > 0
        ? _c("b-pagination", {
            attrs: {
              "total-rows": _vm.total_rows,
              "per-page": 20,
              align: "center",
            },
            model: {
              value: _vm.currentPage,
              callback: function ($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }