var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "back-btn",
      on: {
        click: function ($event) {
          return _vm.onClick()
        },
      },
    },
    [_c("i", { staticClass: "fas fa-chevron-left mr-2" }), _vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }